import React, { useEffect, useState } from "react"
import Header from "../../components/header"
import useSound from "use-sound"
import reminderSound from "../../style/menu.wav"

const ITT = () => {
  const startorder = [
    {
      ordernr: 1,
      startlist: 123,
      starttime: "13:00:00",
      firstname: "Jonathan",
      pcslink: "https://www.procyclingstats.com/rider/jonathan-dibben",
      lastname: "Dibben",
      t: "Lotto Soudal",
    },
    {
      ordernr: 2,
      startlist: 117,
      starttime: "13:01:00",
      firstname: "Guy",
      pcslink: "https://www.procyclingstats.com/rider/guy-sagiv",
      lastname: "Sagiv",
      t: "Israel Start-Up Nation",
    },
    {
      ordernr: 3,
      startlist: 77,
      starttime: "13:02:00",
      firstname: "Marco",
      pcslink: "https://www.procyclingstats.com/rider/marco-mathis",
      lastname: "Mathis",
      t: "Cofidis, Solutions Crédits",
    },
    {
      ordernr: 4,
      startlist: 85,
      starttime: "13:03:00",
      firstname: "Iljo",
      pcslink: "https://www.procyclingstats.com/rider/iljo-keisse",
      lastname: "Keisse",
      t: "Deceuninck - Quick Step",
    },
    {
      ordernr: 5,
      startlist: 83,
      starttime: "13:04:00",
      firstname: "Álvaro José",
      pcslink: "https://www.procyclingstats.com/rider/alvaro-jose-hodeg",
      lastname: "Hodeg",
      t: "Deceuninck - Quick Step",
    },
    {
      ordernr: 6,
      startlist: 215,
      starttime: "13:05:00",
      firstname: "Matteo",
      pcslink: "https://www.procyclingstats.com/rider/matteo-spreafico",
      lastname: "Spreafico",
      t: "Vini Zabù - KTM",
    },
    {
      ordernr: 7,
      startlist: 212,
      starttime: "13:06:00",
      firstname: "Simone",
      pcslink: "https://www.procyclingstats.com/rider/simone-bevilacqua",
      lastname: "Bevilacqua",
      t: "Vini Zabù - KTM",
    },
    {
      ordernr: 8,
      startlist: 112,
      starttime: "13:07:00",
      firstname: "Matthias",
      pcslink: "https://www.procyclingstats.com/rider/matthias-brandle",
      lastname: "Brändle",
      t: "Israel Start-Up Nation",
    },
    {
      ordernr: 9,
      startlist: 45,
      starttime: "13:08:00",
      firstname: "Fabio",
      pcslink: "https://www.procyclingstats.com/rider/fabio-mazzucco",
      lastname: "Mazzucco",
      t: "Bardiani-CSF-Faizanè",
    },
    {
      ordernr: 10,
      startlist: 207,
      starttime: "13:09:00",
      firstname: "Juan Sebastián",
      pcslink: "https://www.procyclingstats.com/rider/juan-sebastian-molano",
      lastname: "Molano",
      t: "UAE-Team Emirates",
    },
    {
      ordernr: 11,
      startlist: 44,
      starttime: "13:10:00",
      firstname: "Giovanni",
      pcslink: "https://www.procyclingstats.com/rider/giovanni-lonardi",
      lastname: "Lonardi",
      t: "Bardiani-CSF-Faizanè",
    },
    {
      ordernr: 12,
      startlist: 103,
      starttime: "13:11:00",
      firstname: "Jacopo",
      pcslink: "https://www.procyclingstats.com/rider/jacopo-guarnieri",
      lastname: "Guarnieri",
      t: "Groupama - FDJ",
    },
    {
      ordernr: 13,
      startlist: 213,
      starttime: "13:12:00",
      firstname: "Marco",
      pcslink: "https://www.procyclingstats.com/rider/marco-frapporti",
      lastname: "Frapporti",
      t: "Vini Zabù - KTM",
    },
    {
      ordernr: 14,
      startlist: 115,
      starttime: "13:13:00",
      firstname: "Alex",
      pcslink: "https://www.procyclingstats.com/rider/alex-dowsett",
      lastname: "Dowsett",
      t: "Israel Start-Up Nation",
    },
    {
      ordernr: 15,
      startlist: 205,
      starttime: "13:14:00",
      firstname: "Fernando",
      pcslink: "https://www.procyclingstats.com/rider/fernando-gaviria",
      lastname: "Gaviria",
      t: "UAE-Team Emirates",
    },
    {
      ordernr: 16,
      startlist: 157,
      starttime: "13:15:00",
      firstname: "Dylan",
      pcslink: "https://www.procyclingstats.com/rider/dylan-sunderland",
      lastname: "Sunderland",
      t: "NTT Pro Cycling",
    },
    {
      ordernr: 17,
      startlist: 97,
      starttime: "13:16:00",
      firstname: "Lachlan",
      pcslink: "https://www.procyclingstats.com/rider/lachlan-morton",
      lastname: "Morton",
      t: "EF Pro Cycling",
    },
    {
      ordernr: 18,
      startlist: 118,
      starttime: "13:17:00",
      firstname: "Rick",
      pcslink: "https://www.procyclingstats.com/rider/rick-zabel",
      lastname: "Zabel",
      t: "Israel Start-Up Nation",
    },
    {
      ordernr: 19,
      startlist: 98,
      starttime: "13:18:00",
      firstname: "James",
      pcslink: "https://www.procyclingstats.com/rider/james-whelan",
      lastname: "Whelan",
      t: "EF Pro Cycling",
    },
    {
      ordernr: 20,
      startlist: 74,
      starttime: "13:19:00",
      firstname: "Nathan",
      pcslink: "https://www.procyclingstats.com/rider/nathan-haas",
      lastname: "Haas",
      t: "Cofidis, Solutions Crédits",
    },
    {
      ordernr: 21,
      startlist: 208,
      starttime: "13:20:00",
      firstname: "Maximiliano",
      pcslink:
        "https://www.procyclingstats.com/rider/ariel-maximiliano-richeze",
      lastname: "Richeze",
      t: "UAE-Team Emirates",
    },
    {
      ordernr: 22,
      startlist: 104,
      starttime: "13:21:00",
      firstname: "Simon",
      pcslink: "https://www.procyclingstats.com/rider/simon-guglielmi",
      lastname: "Guglielmi",
      t: "Groupama - FDJ",
    },
    {
      ordernr: 23,
      startlist: 105,
      starttime: "13:22:00",
      firstname: "Ignatas",
      pcslink: "https://www.procyclingstats.com/rider/ignatas-konovalovas",
      lastname: "Konovalovas",
      t: "Groupama - FDJ",
    },
    {
      ordernr: 24,
      startlist: 43,
      starttime: "13:23:00",
      firstname: "Filippo",
      pcslink: "https://www.procyclingstats.com/rider/filippo-fiorelli",
      lastname: "Fiorelli",
      t: "Bardiani-CSF-Faizanè",
    },
    {
      ordernr: 25,
      startlist: 114,
      starttime: "13:24:00",
      firstname: "Davide",
      pcslink: "https://www.procyclingstats.com/rider/davide-cimolai",
      lastname: "Cimolai",
      t: "Israel Start-Up Nation",
    },
    {
      ordernr: 26,
      startlist: 23,
      starttime: "13:25:00",
      firstname: "Rodrigo",
      pcslink: "https://www.procyclingstats.com/rider/rodrigo-contreras",
      lastname: "Contreras",
      t: "Astana Pro Team",
    },
    {
      ordernr: 27,
      startlist: 101,
      starttime: "13:26:00",
      firstname: "Arnaud",
      pcslink: "https://www.procyclingstats.com/rider/arnaud-demare",
      lastname: "Démare",
      t: "Groupama - FDJ",
    },
    {
      ordernr: 28,
      startlist: 125,
      starttime: "13:27:00",
      firstname: "Adam",
      pcslink: "https://www.procyclingstats.com/rider/adam-hansen",
      lastname: "Hansen",
      t: "Lotto Soudal",
    },
    {
      ordernr: 29,
      startlist: 106,
      starttime: "13:28:00",
      firstname: "Miles",
      pcslink: "https://www.procyclingstats.com/rider/miles-scotson",
      lastname: "Scotson",
      t: "Groupama - FDJ",
    },
    {
      ordernr: 30,
      startlist: 71,
      starttime: "13:29:00",
      firstname: "Elia",
      pcslink: "https://www.procyclingstats.com/rider/elia-viviani",
      lastname: "Viviani",
      t: "Cofidis, Solutions Crédits",
    },
    {
      ordernr: 31,
      startlist: 64,
      starttime: "13:30:00",
      firstname: "Kamil",
      pcslink: "https://www.procyclingstats.com/rider/kamil-gradek",
      lastname: "Gradek",
      t: "CCC Team",
    },
    {
      ordernr: 32,
      startlist: 11,
      starttime: "13:31:00",
      firstname: "Mattia",
      pcslink: "https://www.procyclingstats.com/rider/mattia-bais",
      lastname: "Bais",
      t: "Androni Giocattoli - Sidermec",
    },
    {
      ordernr: 33,
      startlist: 147,
      starttime: "13:32:00",
      firstname: "Albert",
      pcslink: "https://www.procyclingstats.com/rider/albert-torres",
      lastname: "Torres",
      t: "Movistar Team",
    },
    {
      ordernr: 34,
      startlist: 76,
      starttime: "13:33:00",
      firstname: "Mathias",
      pcslink: "https://www.procyclingstats.com/rider/mathias-le-turnier",
      lastname: "Le Turnier",
      t: "Cofidis, Solutions Crédits",
    },
    {
      ordernr: 35,
      startlist: 152,
      starttime: "13:34:00",
      firstname: "Victor",
      pcslink: "https://www.procyclingstats.com/rider/victor-campenaerts",
      lastname: "Campenaerts",
      t: "NTT Pro Cycling",
    },
    {
      ordernr: 36,
      startlist: 48,
      starttime: "13:35:00",
      firstname: "Filippo",
      pcslink: "https://www.procyclingstats.com/rider/filippo-zana",
      lastname: "Zana",
      t: "Bardiani-CSF-Faizanè",
    },
    {
      ordernr: 37,
      startlist: 72,
      starttime: "13:36:00",
      firstname: "Simone",
      pcslink: "https://www.procyclingstats.com/rider/simone-consonni",
      lastname: "Consonni",
      t: "Cofidis, Solutions Crédits",
    },
    {
      ordernr: 38,
      startlist: 53,
      starttime: "13:37:00",
      firstname: "Maciej",
      pcslink: "https://www.procyclingstats.com/rider/maciej-bodnar",
      lastname: "Bodnar",
      t: "BORA - hansgrohe",
    },
    {
      ordernr: 39,
      startlist: 126,
      starttime: "13:38:00",
      firstname: "Matthew",
      pcslink: "https://www.procyclingstats.com/rider/matthew-holmes",
      lastname: "Holmes",
      t: "Lotto Soudal",
    },
    {
      ordernr: 40,
      startlist: 52,
      starttime: "13:39:00",
      firstname: "Cesare",
      pcslink: "https://www.procyclingstats.com/rider/cesare-benedetti",
      lastname: "Benedetti",
      t: "BORA - hansgrohe",
    },
    {
      ordernr: 41,
      startlist: 203,
      starttime: "13:40:00",
      firstname: "Valerio",
      pcslink: "https://www.procyclingstats.com/rider/valerio-conti",
      lastname: "Conti",
      t: "UAE-Team Emirates",
    },
    {
      ordernr: 42,
      startlist: 141,
      starttime: "13:41:00",
      firstname: "Héctor",
      pcslink: "https://www.procyclingstats.com/rider/hector-carretero",
      lastname: "Carretero",
      t: "Movistar Team",
    },
    {
      ordernr: 43,
      startlist: 17,
      starttime: "13:42:00",
      firstname: "Jhonatan",
      pcslink: "https://www.procyclingstats.com/rider/jhonatan-restrepo",
      lastname: "Restrepo",
      t: "Androni Giocattoli - Sidermec",
    },
    {
      ordernr: 44,
      startlist: 216,
      starttime: "13:43:00",
      firstname: "Etienne",
      pcslink: "https://www.procyclingstats.com/rider/etienne-van-empel",
      lastname: "van Empel",
      t: "Vini Zabù - KTM",
    },
    {
      ordernr: 45,
      startlist: 73,
      starttime: "13:44:00",
      firstname: "Nicolas",
      pcslink: "https://www.procyclingstats.com/rider/nicolas-edet",
      lastname: "Edet",
      t: "Cofidis, Solutions Crédits",
    },
    {
      ordernr: 46,
      startlist: 35,
      starttime: "13:45:00",
      firstname: "Domen",
      pcslink: "https://www.procyclingstats.com/rider/domen-novak",
      lastname: "Novak",
      t: "Bahrain - McLaren",
    },
    {
      ordernr: 47,
      startlist: 153,
      starttime: "13:46:00",
      firstname: "Amanuel",
      pcslink: "https://www.procyclingstats.com/rider/amanuel-ghebreigzabhier",
      lastname: "Ghebreigzabhier",
      t: "NTT Pro Cycling",
    },
    {
      ordernr: 48,
      startlist: 12,
      starttime: "13:47:00",
      firstname: "Alessandro",
      pcslink: "https://www.procyclingstats.com/rider/alessandro-bisolti",
      lastname: "Bisolti",
      t: "Androni Giocattoli - Sidermec",
    },
    {
      ordernr: 49,
      startlist: 127,
      starttime: "13:48:00",
      firstname: "Stefano",
      pcslink: "https://www.procyclingstats.com/rider/stefano-oldani",
      lastname: "Oldani",
      t: "Lotto Soudal",
    },
    {
      ordernr: 50,
      startlist: 31,
      starttime: "13:49:00",
      firstname: "Yukiya",
      pcslink: "https://www.procyclingstats.com/rider/yukiya-arashiro",
      lastname: "Arashiro",
      t: "Bahrain - McLaren",
    },
    {
      ordernr: 51,
      startlist: 34,
      starttime: "13:50:00",
      firstname: "Eros",
      pcslink: "https://www.procyclingstats.com/rider/eros-capecchi",
      lastname: "Capecchi",
      t: "Bahrain - McLaren",
    },
    {
      ordernr: 52,
      startlist: 62,
      starttime: "13:51:00",
      firstname: "Josef",
      pcslink: "https://www.procyclingstats.com/rider/josef-cerny",
      lastname: "Černý",
      t: "CCC Team",
    },
    {
      ordernr: 53,
      startlist: 158,
      starttime: "13:52:00",
      firstname: "Danilo",
      pcslink: "https://www.procyclingstats.com/rider/danilo-wyss",
      lastname: "Wyss",
      t: "NTT Pro Cycling",
    },
    {
      ordernr: 54,
      startlist: 13,
      starttime: "13:53:00",
      firstname: "Jefferson Alexander",
      pcslink:
        "https://www.procyclingstats.com/rider/jefferson-alexander-cepeda",
      lastname: "Cepeda",
      t: "Androni Giocattoli - Sidermec",
    },
    {
      ordernr: 55,
      startlist: 214,
      starttime: "13:54:00",
      firstname: "Lorenzo",
      pcslink: "https://www.procyclingstats.com/rider/lorenzo-rota",
      lastname: "Rota",
      t: "Vini Zabù - KTM",
    },
    {
      ordernr: 56,
      startlist: 163,
      starttime: "13:55:00",
      firstname: "Rohan",
      pcslink: "https://www.procyclingstats.com/rider/rohan-dennis",
      lastname: "Dennis",
      t: "INEOS Grenadiers",
    },
    {
      ordernr: 57,
      startlist: 14,
      starttime: "13:56:00",
      firstname: "Luca",
      pcslink: "https://www.procyclingstats.com/rider/luca-chirico",
      lastname: "Chirico",
      t: "Androni Giocattoli - Sidermec",
    },
    {
      ordernr: 58,
      startlist: 182,
      starttime: "13:57:00",
      firstname: "Nico",
      pcslink: "https://www.procyclingstats.com/rider/nico-denz",
      lastname: "Denz",
      t: "Team Sunweb",
    },
    {
      ordernr: 59,
      startlist: 218,
      starttime: "13:58:00",
      firstname: "Edoardo",
      pcslink: "https://www.procyclingstats.com/rider/edoardo-zardini",
      lastname: "Zardini",
      t: "Vini Zabù - KTM",
    },
    {
      ordernr: 60,
      startlist: 66,
      starttime: "13:59:00",
      firstname: "Kamil",
      pcslink: "https://www.procyclingstats.com/rider/kamil-malecki",
      lastname: "Małecki",
      t: "CCC Team",
    },
    {
      ordernr: 61,
      startlist: 18,
      starttime: "14:00:00",
      firstname: "Josip",
      pcslink: "https://www.procyclingstats.com/rider/josip-rumac",
      lastname: "Rumac",
      t: "Androni Giocattoli - Sidermec",
    },
    {
      ordernr: 62,
      startlist: 15,
      starttime: "14:01:00",
      firstname: "Simon",
      pcslink: "https://www.procyclingstats.com/rider/simon-pellaud",
      lastname: "Pellaud",
      t: "Androni Giocattoli - Sidermec",
    },
    {
      ordernr: 63,
      startlist: 46,
      starttime: "14:02:00",
      firstname: "Francesco",
      pcslink: "https://www.procyclingstats.com/rider/francesco-romano",
      lastname: "Romano",
      t: "Bardiani-CSF-Faizanè",
    },
    {
      ordernr: 64,
      startlist: 16,
      starttime: "14:03:00",
      firstname: "Simone",
      pcslink: "https://www.procyclingstats.com/rider/simone-ravanelli",
      lastname: "Ravanelli",
      t: "Androni Giocattoli - Sidermec",
    },
    {
      ordernr: 65,
      startlist: 38,
      starttime: "14:04:00",
      firstname: "Jan",
      pcslink: "https://www.procyclingstats.com/rider/jan-tratnik",
      lastname: "Tratnik",
      t: "Bahrain - McLaren",
    },
    {
      ordernr: 66,
      startlist: 82,
      starttime: "14:05:00",
      firstname: "Davide",
      pcslink: "https://www.procyclingstats.com/rider/davide-ballerini",
      lastname: "Ballerini",
      t: "Deceuninck - Quick Step",
    },
    {
      ordernr: 67,
      startlist: 142,
      starttime: "14:06:00",
      firstname: "Dario",
      pcslink: "https://www.procyclingstats.com/rider/dario-cataldo",
      lastname: "Cataldo",
      t: "Movistar Team",
    },
    {
      ordernr: 68,
      startlist: 3,
      starttime: "14:07:00",
      firstname: "Geoffrey",
      pcslink: "https://www.procyclingstats.com/rider/geoffrey-bouchard",
      lastname: "Bouchard",
      t: "AG2R La Mondiale",
    },
    {
      ordernr: 69,
      startlist: 102,
      starttime: "14:08:00",
      firstname: "Kilian",
      pcslink: "https://www.procyclingstats.com/rider/kilian-frankiny",
      lastname: "Frankiny",
      t: "Groupama - FDJ",
    },
    {
      ordernr: 70,
      startlist: 156,
      starttime: "14:09:00",
      firstname: "Matteo",
      pcslink: "https://www.procyclingstats.com/rider/matteo-sobrero",
      lastname: "Sobrero",
      t: "NTT Pro Cycling",
    },
    {
      ordernr: 71,
      startlist: 47,
      starttime: "14:10:00",
      firstname: "Alessandro",
      pcslink: "https://www.procyclingstats.com/rider/alessandro-tonelli",
      lastname: "Tonelli",
      t: "Bardiani-CSF-Faizanè",
    },
    {
      ordernr: 72,
      startlist: 202,
      starttime: "14:11:00",
      firstname: "Mikkel",
      pcslink: "https://www.procyclingstats.com/rider/mikkel-bjerg",
      lastname: "Bjerg",
      t: "UAE-Team Emirates",
    },
    {
      ordernr: 73,
      startlist: 22,
      starttime: "14:12:00",
      firstname: "Manuele",
      pcslink: "https://www.procyclingstats.com/rider/manuele-boaro",
      lastname: "Boaro",
      t: "Astana Pro Team",
    },
    {
      ordernr: 74,
      startlist: 51,
      starttime: "14:13:00",
      firstname: "Peter",
      pcslink: "https://www.procyclingstats.com/rider/peter-sagan",
      lastname: "Sagan",
      t: "BORA - hansgrohe",
    },
    {
      ordernr: 75,
      startlist: 93,
      starttime: "14:14:00",
      firstname: "Simon",
      pcslink: "https://www.procyclingstats.com/rider/simon-clarke",
      lastname: "Clarke",
      t: "EF Pro Cycling",
    },
    {
      ordernr: 76,
      startlist: 183,
      starttime: "14:15:00",
      firstname: "Chad",
      pcslink: "https://www.procyclingstats.com/rider/chad-haga",
      lastname: "Haga",
      t: "Team Sunweb",
    },
    {
      ordernr: 77,
      startlist: 78,
      starttime: "14:16:00",
      firstname: "Stéphane",
      pcslink: "https://www.procyclingstats.com/rider/stephane-rossetto",
      lastname: "Rossetto",
      t: "Cofidis, Solutions Crédits",
    },
    {
      ordernr: 78,
      startlist: 211,
      starttime: "14:17:00",
      firstname: "Giovanni",
      pcslink: "https://www.procyclingstats.com/rider/giovanni-visconti",
      lastname: "Visconti",
      t: "Vini Zabù - KTM",
    },
    {
      ordernr: 79,
      startlist: 41,
      starttime: "14:18:00",
      firstname: "Giovanni",
      pcslink: "https://www.procyclingstats.com/rider/giovanni-carboni",
      lastname: "Carboni",
      t: "Bardiani-CSF-Faizanè",
    },
    {
      ordernr: 80,
      startlist: 92,
      starttime: "14:19:00",
      firstname: "Jonathan Klever",
      pcslink: "https://www.procyclingstats.com/rider/jonathan-klever-caicedo",
      lastname: "Caicedo",
      t: "EF Pro Cycling",
    },
    {
      ordernr: 81,
      startlist: 164,
      starttime: "14:20:00",
      firstname: "Filippo",
      pcslink: "https://www.procyclingstats.com/rider/filippo-ganna",
      lastname: "Ganna",
      t: "INEOS Grenadiers",
    },
    {
      ordernr: 82,
      startlist: 7,
      starttime: "14:21:00",
      firstname: "Andrea",
      pcslink: "https://www.procyclingstats.com/rider/andrea-vendrame",
      lastname: "Vendrame",
      t: "AG2R La Mondiale",
    },
    {
      ordernr: 83,
      startlist: 121,
      starttime: "14:22:00",
      firstname: "Sander",
      pcslink: "https://www.procyclingstats.com/rider/sander-armee",
      lastname: "Armée",
      t: "Lotto Soudal",
    },
    {
      ordernr: 84,
      startlist: 124,
      starttime: "14:23:00",
      firstname: "Carl Fredrik",
      pcslink: "https://www.procyclingstats.com/rider/carl-fredrik-hagen",
      lastname: "Hagen",
      t: "Lotto Soudal",
    },
    {
      ordernr: 85,
      startlist: 144,
      starttime: "14:24:00",
      firstname: "Einer Augusto",
      pcslink:
        "https://www.procyclingstats.com/rider/einer-augusto-rubio-reyes",
      lastname: "Rubio",
      t: "Movistar Team",
    },
    {
      ordernr: 86,
      startlist: 122,
      starttime: "14:25:00",
      firstname: "Thomas",
      pcslink: "https://www.procyclingstats.com/rider/thomas-de-gendt",
      lastname: "De Gendt",
      t: "Lotto Soudal",
    },
    {
      ordernr: 87,
      startlist: 192,
      starttime: "14:26:00",
      firstname: "Julien",
      pcslink: "https://www.procyclingstats.com/rider/julien-bernard",
      lastname: "Bernard",
      t: "Trek - Segafredo",
    },
    {
      ordernr: 88,
      startlist: 146,
      starttime: "14:27:00",
      firstname: "Eduardo",
      pcslink: "https://www.procyclingstats.com/rider/eduardo-sepulveda",
      lastname: "Sepúlveda",
      t: "Movistar Team",
    },
    {
      ordernr: 89,
      startlist: 5,
      starttime: "14:28:00",
      firstname: "Jaakko",
      pcslink: "https://www.procyclingstats.com/rider/jaakko-hanninen",
      lastname: "Hänninen",
      t: "AG2R La Mondiale",
    },
    {
      ordernr: 90,
      startlist: 32,
      starttime: "14:29:00",
      firstname: "Enrico",
      pcslink: "https://www.procyclingstats.com/rider/enrico-battaglin",
      lastname: "Battaglin",
      t: "Bahrain - McLaren",
    },
    {
      ordernr: 91,
      startlist: 195,
      starttime: "14:30:00",
      firstname: "Nicola",
      pcslink: "https://www.procyclingstats.com/rider/nicola-conci",
      lastname: "Conci",
      t: "Trek - Segafredo",
    },
    {
      ordernr: 92,
      startlist: 204,
      starttime: "14:31:00",
      firstname: "Joe",
      pcslink: "https://www.procyclingstats.com/rider/joe-dombrowski",
      lastname: "Dombrowski",
      t: "UAE-Team Emirates",
    },
    {
      ordernr: 93,
      startlist: 75,
      starttime: "14:32:00",
      firstname: "Jesper",
      pcslink: "https://www.procyclingstats.com/rider/jesper-hansen-1",
      lastname: "Hansen",
      t: "Cofidis, Solutions Crédits",
    },
    {
      ordernr: 94,
      startlist: 116,
      starttime: "14:33:00",
      firstname: "Daniel",
      pcslink: "https://www.procyclingstats.com/rider/daniel-navarro",
      lastname: "Navarro",
      t: "Israel Start-Up Nation",
    },
    {
      ordernr: 95,
      startlist: 167,
      starttime: "14:34:00",
      firstname: "Salvatore",
      pcslink: "https://www.procyclingstats.com/rider/salvatore-puccio",
      lastname: "Puccio",
      t: "INEOS Grenadiers",
    },
    {
      ordernr: 96,
      startlist: 151,
      starttime: "14:35:00",
      firstname: "Louis",
      pcslink: "https://www.procyclingstats.com/rider/louis-meintjes",
      lastname: "Meintjes",
      t: "NTT Pro Cycling",
    },
    {
      ordernr: 97,
      startlist: 25,
      starttime: "14:36:00",
      firstname: "Jonas",
      pcslink: "https://www.procyclingstats.com/rider/jonas-gregaard",
      lastname: "Gregaard",
      t: "Astana Pro Team",
    },
    {
      ordernr: 98,
      startlist: 67,
      starttime: "14:37:00",
      firstname: "Joey",
      pcslink: "https://www.procyclingstats.com/rider/joey-rosskopf",
      lastname: "Rosskopf",
      t: "CCC Team",
    },
    {
      ordernr: 99,
      startlist: 194,
      starttime: "14:38:00",
      firstname: "Giulio",
      pcslink: "https://www.procyclingstats.com/rider/giulio-ciccone",
      lastname: "Ciccone",
      t: "Trek - Segafredo",
    },
    {
      ordernr: 100,
      startlist: 36,
      starttime: "14:39:00",
      firstname: "Mark",
      pcslink: "https://www.procyclingstats.com/rider/mark-padun",
      lastname: "Padun",
      t: "Bahrain - McLaren",
    },
    {
      ordernr: 101,
      startlist: 154,
      starttime: "14:40:00",
      firstname: "Ben",
      pcslink: "https://www.procyclingstats.com/rider/ben-o-connor",
      lastname: "O'Connor",
      t: "NTT Pro Cycling",
    },
    {
      ordernr: 102,
      startlist: 58,
      starttime: "14:41:00",
      firstname: "Paweł",
      pcslink: "https://www.procyclingstats.com/rider/pawel-poljanski",
      lastname: "Poljański",
      t: "BORA - hansgrohe",
    },
    {
      ordernr: 103,
      startlist: 27,
      starttime: "14:42:00",
      firstname: "Óscar",
      pcslink: "https://www.procyclingstats.com/rider/oscar-rodriguez",
      lastname: "Rodríguez",
      t: "Astana Pro Team",
    },
    {
      ordernr: 104,
      startlist: 193,
      starttime: "14:43:00",
      firstname: "Gianluca",
      pcslink: "https://www.procyclingstats.com/rider/gianluca-brambilla",
      lastname: "Brambilla",
      t: "Trek - Segafredo",
    },
    {
      ordernr: 105,
      startlist: 63,
      starttime: "14:44:00",
      firstname: "Víctor",
      pcslink: "https://www.procyclingstats.com/rider/victor-de-la-parte",
      lastname: "de la Parte",
      t: "CCC Team",
    },
    {
      ordernr: 106,
      startlist: 2,
      starttime: "14:45:00",
      firstname: "François",
      pcslink: "https://www.procyclingstats.com/rider/francois-bidard",
      lastname: "Bidard",
      t: "AG2R La Mondiale",
    },
    {
      ordernr: 107,
      startlist: 197,
      starttime: "14:46:00",
      firstname: "Antonio",
      pcslink: "https://www.procyclingstats.com/rider/antonio-nibali",
      lastname: "Nibali",
      t: "Trek - Segafredo",
    },
    {
      ordernr: 108,
      startlist: 201,
      starttime: "14:47:00",
      firstname: "Diego",
      pcslink: "https://www.procyclingstats.com/rider/diego-ulissi",
      lastname: "Ulissi",
      t: "UAE-Team Emirates",
    },
    {
      ordernr: 109,
      startlist: 88,
      starttime: "14:48:00",
      firstname: "Pieter",
      pcslink: "https://www.procyclingstats.com/rider/pieter-serry",
      lastname: "Serry",
      t: "Deceuninck - Quick Step",
    },
    {
      ordernr: 110,
      startlist: 148,
      starttime: "14:49:00",
      firstname: "Davide",
      pcslink: "https://www.procyclingstats.com/rider/davide-villella",
      lastname: "Villella",
      t: "Movistar Team",
    },
    {
      ordernr: 111,
      startlist: 166,
      starttime: "14:50:00",
      firstname: "Jhonatan",
      pcslink: "https://www.procyclingstats.com/rider/jhonatan-narvaez",
      lastname: "Narváez",
      t: "INEOS Grenadiers",
    },
    {
      ordernr: 112,
      startlist: 96,
      starttime: "14:51:00",
      firstname: "Tanel",
      pcslink: "https://www.procyclingstats.com/rider/tanel-kangert",
      lastname: "Kangert",
      t: "EF Pro Cycling",
    },
    {
      ordernr: 113,
      startlist: 188,
      starttime: "14:52:00",
      firstname: "Martijn",
      pcslink: "https://www.procyclingstats.com/rider/martijn-tusveld",
      lastname: "Tusveld",
      t: "Team Sunweb",
    },
    {
      ordernr: 114,
      startlist: 184,
      starttime: "14:53:00",
      firstname: "Chris",
      pcslink: "https://www.procyclingstats.com/rider/chris-hamilton",
      lastname: "Hamilton",
      t: "Team Sunweb",
    },
    {
      ordernr: 115,
      startlist: 128,
      starttime: "14:54:00",
      firstname: "Harm",
      pcslink: "https://www.procyclingstats.com/rider/harm-vanhoucke",
      lastname: "Vanhoucke",
      t: "Lotto Soudal",
    },
    {
      ordernr: 116,
      startlist: 168,
      starttime: "14:55:00",
      firstname: "Ben",
      pcslink: "https://www.procyclingstats.com/rider/ben-swift",
      lastname: "Swift",
      t: "INEOS Grenadiers",
    },
    {
      ordernr: 117,
      startlist: 24,
      starttime: "14:56:00",
      firstname: "Fabio",
      pcslink: "https://www.procyclingstats.com/rider/fabio-felline",
      lastname: "Felline",
      t: "Astana Pro Team",
    },
    {
      ordernr: 118,
      startlist: 95,
      starttime: "14:57:00",
      firstname: "Ruben",
      pcslink: "https://www.procyclingstats.com/rider/ruben-guerreiro",
      lastname: "Guerreiro",
      t: "EF Pro Cycling",
    },
    {
      ordernr: 119,
      startlist: 196,
      starttime: "14:58:00",
      firstname: "Jacopo",
      pcslink: "https://www.procyclingstats.com/rider/jacopo-mosca",
      lastname: "Mosca",
      t: "Trek - Segafredo",
    },
    {
      ordernr: 120,
      startlist: 8,
      starttime: "14:59:00",
      firstname: "Larry",
      pcslink: "https://www.procyclingstats.com/rider/lawrence-warbasse",
      lastname: "Warbasse",
      t: "AG2R La Mondiale",
    },
    {
      ordernr: 121,
      startlist: 84,
      starttime: "15:00:00",
      firstname: "Mikkel Frølich",
      pcslink: "https://www.procyclingstats.com/rider/mikkel-honore",
      lastname: "Honoré",
      t: "Deceuninck - Quick Step",
    },
    {
      ordernr: 122,
      startlist: 68,
      starttime: "15:01:00",
      firstname: "Attila",
      pcslink: "https://www.procyclingstats.com/rider/attila-valter",
      lastname: "Valter",
      t: "CCC Team",
    },
    {
      ordernr: 123,
      startlist: 54,
      starttime: "15:02:00",
      firstname: "Matteo",
      pcslink: "https://www.procyclingstats.com/rider/matteo-fabbro",
      lastname: "Fabbro",
      t: "BORA - hansgrohe",
    },
    {
      ordernr: 124,
      startlist: 187,
      starttime: "15:03:00",
      firstname: "Sam",
      pcslink: "https://www.procyclingstats.com/rider/sam-oomen",
      lastname: "Oomen",
      t: "Team Sunweb",
    },
    {
      ordernr: 125,
      startlist: 6,
      starttime: "15:04:00",
      firstname: "Aurélien",
      pcslink: "https://www.procyclingstats.com/rider/aurelien-paret-peintre",
      lastname: "Paret-Peintre",
      t: "AG2R La Mondiale",
    },
    {
      ordernr: 126,
      startlist: 162,
      starttime: "15:05:00",
      firstname: "Jonathan",
      pcslink: "https://www.procyclingstats.com/rider/jonathan-castroviejo",
      lastname: "Castroviejo",
      t: "INEOS Grenadiers",
    },
    {
      ordernr: 127,
      startlist: 61,
      starttime: "15:06:00",
      firstname: "Ilnur",
      pcslink: "https://www.procyclingstats.com/rider/ilnur-zakarin",
      lastname: "Zakarin",
      t: "CCC Team",
    },
    {
      ordernr: 128,
      startlist: 145,
      starttime: "15:07:00",
      firstname: "Sergio",
      pcslink: "https://www.procyclingstats.com/rider/sergio-samitier",
      lastname: "Samitier",
      t: "Movistar Team",
    },
    {
      ordernr: 129,
      startlist: 86,
      starttime: "15:10:00",
      firstname: "James",
      pcslink: "https://www.procyclingstats.com/rider/james-knox",
      lastname: "Knox",
      t: "Deceuninck - Quick Step",
    },
    {
      ordernr: 130,
      startlist: 37,
      starttime: "15:13:00",
      firstname: "Hermann",
      pcslink: "https://www.procyclingstats.com/rider/hermann-pernsteiner",
      lastname: "Pernsteiner",
      t: "Bahrain - McLaren",
    },
    {
      ordernr: 131,
      startlist: 143,
      starttime: "15:16:00",
      firstname: "Antonio",
      pcslink: "https://www.procyclingstats.com/rider/antonio-pedrero",
      lastname: "Pedrero",
      t: "Movistar Team",
    },
    {
      ordernr: 132,
      startlist: 165,
      starttime: "15:19:00",
      firstname: "Tao",
      pcslink: "https://www.procyclingstats.com/rider/tao-geoghegan-hart",
      lastname: "Geoghegan Hart",
      t: "INEOS Grenadiers",
    },
    {
      ordernr: 133,
      startlist: 206,
      starttime: "15:22:00",
      firstname: "Brandon",
      pcslink: "https://www.procyclingstats.com/rider/brandon-mcnulty",
      lastname: "McNulty",
      t: "UAE-Team Emirates",
    },
    {
      ordernr: 134,
      startlist: 21,
      starttime: "15:25:00",
      firstname: "Jakob",
      pcslink: "https://www.procyclingstats.com/rider/jakob-fuglsang",
      lastname: "Fuglsang",
      t: "Astana Pro Team",
    },
    {
      ordernr: 135,
      startlist: 87,
      starttime: "15:28:00",
      firstname: "Fausto",
      pcslink: "https://www.procyclingstats.com/rider/fausto-masnada",
      lastname: "Masnada",
      t: "Deceuninck - Quick Step",
    },
    {
      ordernr: 136,
      startlist: 57,
      starttime: "15:31:00",
      firstname: "Rafał",
      pcslink: "https://www.procyclingstats.com/rider/rafal-majka",
      lastname: "Majka",
      t: "BORA - hansgrohe",
    },
    {
      ordernr: 137,
      startlist: 185,
      starttime: "15:34:00",
      firstname: "Jai",
      pcslink: "https://www.procyclingstats.com/rider/jai-hindley",
      lastname: "Hindley",
      t: "Team Sunweb",
    },
    {
      ordernr: 138,
      startlist: 56,
      starttime: "15:37:00",
      firstname: "Patrick",
      pcslink: "https://www.procyclingstats.com/rider/patrick-konrad",
      lastname: "Konrad",
      t: "BORA - hansgrohe",
    },
    {
      ordernr: 139,
      startlist: 191,
      starttime: "15:40:00",
      firstname: "Vincenzo",
      pcslink: "https://www.procyclingstats.com/rider/vincenzo-nibali",
      lastname: "Nibali",
      t: "Trek - Segafredo",
    },
    {
      ordernr: 140,
      startlist: 155,
      starttime: "15:43:00",
      firstname: "Domenico",
      pcslink: "https://www.procyclingstats.com/rider/domenico-pozzovivo",
      lastname: "Pozzovivo",
      t: "NTT Pro Cycling",
    },
    {
      ordernr: 141,
      startlist: 33,
      starttime: "15:46:00",
      firstname: "Pello",
      pcslink: "https://www.procyclingstats.com/rider/pello-bilbao",
      lastname: "Bilbao",
      t: "Bahrain - McLaren",
    },
    {
      ordernr: 142,
      startlist: 181,
      starttime: "15:49:00",
      firstname: "Wilco",
      pcslink: "https://www.procyclingstats.com/rider/wilco-kelderman",
      lastname: "Kelderman",
      t: "Team Sunweb",
    },
    {
      ordernr: 143,
      startlist: 81,
      starttime: "15:52:00",
      firstname: "João",
      pcslink: "https://www.procyclingstats.com/rider/joao-almeida",
      lastname: "Almeida",
      t: "Deceuninck - Quick Step",
    },
  ]
  const [showTop5, setShowTop5] = useState(false)

  const convertDate = () => {
    const userDate = new Date()
    const raceDate = userDate.toLocaleString("en-US", {
      timeZone: "Europe/Amsterdam",
    })
    return new Date(raceDate)
  }
  const [currentRaceTime, setCurrentRaceTime] = useState(convertDate())

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentRaceTime(convertDate())
    }, 1000)
  })

  const secondsToHms = d => {
    d = Number(d)
    var h = Math.floor(d / 3600)
    var m = Math.floor((d % 3600) / 60)
    var s = Math.floor((d % 3600) % 60)

    var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : ""
    var mDisplay = m > 0 || h > 0 ? m + (m == 1 ? ":" : ":") : ""
    var sDisplay = s >= 0 ? s + (s == 1 ? "" : "") : ""
    return hDisplay + mDisplay + sDisplay
  }

  const [showReminderModal, setShowReminderModal] = useState(false)
  const [selectRiderText, setSelectRiderText] = useState("")
  const [reminderMessage, setReminderMessage] = useState("")
  const [reminderRider, setReminderRider] = useState([null, null])

  const [play] = useSound(reminderSound)

  const setReminder = (starttime, name, timeDiff) => {
    setReminderMessage(
      `You've set a reminder for ${name}, who will start at ${starttime}`
    )
    console.log(-timeDiff - 120)
    setReminderRider([name, -timeDiff - 120])
  }

  useEffect(() => {
    if (reminderRider[0] != null) {
      const timer = setTimeout(() => {
        play()
        window.alert(`${reminderRider[0]} is starting in 2 minutes!`)
      }, reminderRider[1] * 1000)
      return () => clearTimeout(timer)
    }
  }, [reminderRider])

  const title = `Giro 2020 ITT Cernusco sul Naviglio - Milan`

  const testTimeCorrection = 4000

  return (
    <div className="App">
      <div className="container">
        <Header title={title} race={"Giro"} />
        <div className="timetrialbuttons">
          <button
            className={`currentRider ${showTop5 ? "" : "selectedButton"}`}
            onClick={() => {
              setShowTop5(false)
            }}
          >
            Next Starter
          </button>
          <button
            className={`currentRider ${showTop5 ? "selectedButton" : ""}`}
            onClick={() => {
              setShowTop5(true)
            }}
          >
            Top 10 GC
          </button>
        </div>
        <div className="listContainer">
          {showTop5 &&
            startorder
              .filter((rider, index) => {
                const totalRidersNr = startorder.length
                if (index + 1 > totalRidersNr - 10) {
                  return true
                } else {
                }
              })
              .map(rider => {
                const concatDatetime = `Tue May 25 2021 ${rider.starttime}`
                const riderStarttime = new Date(concatDatetime)
                const timeDiff = Math.floor(
                  (currentRaceTime.getTime() - riderStarttime.getTime()) /
                    1000 +
                    testTimeCorrection
                )
                const timeDiffNice = secondsToHms(timeDiff * -1)
                return (
                  <div
                    key={rider.ordernr}
                    className={`ttrider ${
                      timeDiff > -60 && timeDiff < 0 ? "nextRider" : "" // Check rider startnumber for points where timegap changes, then add a multiplier for that, e.g. startnumber > 128, times 2
                    }`}
                  >
                    <p className="ordernr">#{rider.ordernr}</p>
                    <div className="nameandteam">
                      <h3>
                        {rider.firstname} {rider.lastname}
                      </h3>
                      <p className="teamname">{rider.t}</p>
                    </div>
                    <p className="secondsLeft">
                      {timeDiff < 0 ? `${timeDiffNice}` : `started`}
                    </p>
                  </div>
                )
              })}

          {!showTop5 &&
            startorder
              .filter(rider => {
                const concatDatetime = `Tue May 25 2021 ${rider.starttime}`
                const riderStarttime = new Date(concatDatetime)
                const timeDiff = Math.floor(
                  (currentRaceTime.getTime() - riderStarttime.getTime()) /
                    1000 +
                    testTimeCorrection
                )
                if (timeDiff > -900 && timeDiff < 120) {
                  return true
                } else {
                }
              })
              .map(rider => {
                const concatDatetime = `Tue May 25 2021 ${rider.starttime}`
                const riderStarttime = new Date(concatDatetime)
                const timeDiff = Math.floor(
                  (currentRaceTime.getTime() - riderStarttime.getTime()) /
                    1000 +
                    testTimeCorrection
                )
                const timeDiffNice = secondsToHms(timeDiff * -1)
                return (
                  <div
                    key={rider.ordernr}
                    className={`ttrider ${
                      timeDiff > -61 && timeDiff < 0 ? "nextRider" : ""
                    }`}
                  >
                    <p className="ordernr">#{rider.ordernr}</p>
                    <div className="nameandteam">
                      <h3>
                        {rider.firstname} {rider.lastname}
                      </h3>
                      <p className="teamname">{rider.t}</p>
                    </div>
                    <p className="secondsLeft">
                      {timeDiff < 0 ? `${timeDiffNice}` : `started`}
                    </p>
                  </div>
                )
              })}
        </div>
        {showReminderModal && (
          <div className="reminderModal">
            <h2>Select a rider</h2>
            <p className="reminderDescription">
              You'll receive a reminder{" "}
              <span className="underline"> 2 minutes</span> before your selected
              rider starts
            </p>
            <input
              type="text"
              className="reminderInput"
              value={selectRiderText}
              placeholder="Type a name..."
              onChange={e => {
                setSelectRiderText(e.target.value)
              }}
            ></input>
            <div className="reminderRidersResults">
              {startorder
                .filter(rider => {
                  const riderFullName = (
                    rider.firstname +
                    " " +
                    rider.lastname
                  ).toLowerCase()
                  const searchTerm = selectRiderText.toLowerCase()
                  if (
                    riderFullName.includes(searchTerm) &&
                    searchTerm.length > 0
                  ) {
                    return true
                  } else {
                    return false
                  }
                })
                .slice(0, 4)
                .map(rider => {
                  const riderFullName = rider.firstname + " " + rider.lastname
                  const concatDatetime = `Tue May 25 2021 ${rider.starttime}`
                  const riderStarttime = new Date(concatDatetime)
                  const timeDiff = Math.floor(
                    (currentRaceTime.getTime() - riderStarttime.getTime()) /
                      1000 +
                      testTimeCorrection
                  )
                  const timeDiffNice = secondsToHms(timeDiff * -1)

                  return (
                    <div
                      className="reminderRider"
                      style={{ opacity: `${timeDiff < 0 ? 1 : 0.5}` }}
                      onClick={() => {
                        if (timeDiff < 120) {
                          setReminder(rider.starttime, riderFullName, timeDiff)
                        }
                      }}
                    >
                      <span>
                        {rider.firstname + " "} {rider.lastname}
                      </span>
                      {/* <span>{rider.starttime}</span> */}
                      <span>
                        {timeDiff < 0 ? `${timeDiffNice}` : `started`}
                      </span>
                    </div>
                  )
                })}
            </div>
            {reminderMessage && (
              <div className="reminderMessage">{reminderMessage}</div>
            )}
          </div>
        )}
        <button
          className="setTTAlert"
          onClick={() => {
            setShowReminderModal(!showReminderModal)
          }}
        >
          {" "}
          <img
            className="watchIcon"
            alt="watch icon"
            src={require("../../style/stopwatch.svg")}
          />
          {!showReminderModal
            ? `Set reminder for a rider`
            : `Back to start order`}
        </button>
      </div>
    </div>
  )
}

export default ITT
